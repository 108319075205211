import { useEffect, useRef, useState } from "preact/compat";

import "./ScrollAnimationAccordion.scss";
import classNames from "classnames";

export interface Item {
  id: string;
  title: string;
  caption: string;
  alt?: string;
  badge: string;
}

interface Props {
  items: Item[];
  className: string;
  children?: unknown;
  badgeColor?: string;
  dependencies?: unknown[];
}

export default function ScrollAnimationAccordion({
  items,
  className,
  children,
  badgeColor,
  dependencies = [],
}: Props) {
  const ref = useRef<HTMLUListElement>(null);
  const [indexActive, setIndexActive] = useState(0);
  let scrollTimeout: ReturnType<typeof setTimeout>;

  function handleScroll() {
    function findCurrentIndex() {
      if (!ref.current) return -1;
      const { scrollLeft, scrollWidth, clientWidth, children } = ref.current;

      if (scrollLeft === 0) {
        return 0;
      }
      if (scrollLeft + clientWidth === scrollWidth) {
        return children.length - 1;
      }
      const { width: parentWidth } = ref.current!.getBoundingClientRect();
      const [{ index }] = Array.from(ref.current!.children)
        .map((item, index) => {
          const { x, width } = item.getBoundingClientRect();
          return { index, distance: Math.abs(x + width / 2 - parentWidth / 2) };
        })
        .sort((a, b) => a.distance - b.distance);
      return index;
    }

    function calcAndSetIndex() {
      const index = findCurrentIndex();
      setIndexActive(index);
    }

    if (window.innerWidth >= 768) {
      return;
    }

    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }
    scrollTimeout = setTimeout(() => calcAndSetIndex(), 50);
  }

  function handleClick(index: number) {
    if (!ref.current) return;
    const { width } = ref.current.getBoundingClientRect();
    // We need this 0,8 coefficient because the element has flex-basis of 80vw;
    ref.current.scrollTo({
      left: width * 0.8 * index,
      behavior: "smooth",
    });
  }

  useEffect(() => {
    if (typeof window === "undefined") {
      return function empty() {};
    }
    const badges =
      document.querySelectorAll<HTMLSpanElement>(".saa__item-badge");
    badges.forEach((badge) => {
      const { width } = badge.parentElement!.getBoundingClientRect();
      badge.style.setProperty("--saa-badge-translate", width / 2 + "px");
    });
  }, dependencies);

  return (
    <div className="saa__container">
      <div className="saa__image">
        <div className="saa__image-container">
          {items.map(({ id, alt }, index) => (
            <picture key={id}>
              <source
                media="(min-width: 768px)"
                srcSet={`/img/app/desktop/${id}.png 1x, /img/app/desktop/${id}@2x.png 2x`}
                type="image/png"
              />
              <source
                srcSet={`/img/app/mobile/${id}.png 1x, /img/app/mobile/${id}@2x.png 2x`}
                type="image/png"
              />
              <img
                height={446}
                width={272}
                src={`/img/app/mobile/${id}.png`}
                alt={alt}
                loading="lazy"
                decoding="async"
                srcSet={`/img/app/mobile/${id}@2x.png 2x`}
                className={classNames("saa__picture", {
                  saa__picture_hidden: index !== indexActive,
                })}
              />
            </picture>
          ))}
        </div>
      </div>
      <div className="saa__info">
        <div className="saa__wrapper">
          <ul className={`saa ${className}`} ref={ref} onScroll={handleScroll}>
            {items.map((item, index) => (
              <li
                className={classNames("saa__item", {
                  saa__item_active: index === indexActive,
                })}
                id={`saa-item-${index}`}
              >
                <button
                  className="saa__item-button"
                  onClick={() => setIndexActive(index)}
                >
                  <div className="saa__item-button-wrapper">
                    <h3 className="saa__item-title">{item.title}</h3>
                    <span className="saa__item-title saa__item-title_hidden">
                      {item.title}
                    </span>
                    {item.badge !== "" ? (
                      <span
                        className="saa__item-badge"
                        style={
                          badgeColor ? { backgroundColor: badgeColor } : {}
                        }
                      >
                        {item.badge}
                      </span>
                    ) : undefined}
                  </div>
                </button>
                <p className="saa__item-text">{item.caption}</p>
              </li>
            ))}
          </ul>
          <div className="saa__buttons">
            {items.map((item, index) => (
              <button
                onClick={() => handleClick(index)}
                className={classNames("saa__button", {
                  saa__button_active: index === indexActive,
                })}
              >
                <span className="visually-hidden">{index}</span>
              </button>
            ))}
          </div>
        </div>
        {children}
      </div>
    </div>
  );
}
